import React from 'react'
import { WorkHeading, Time } from '../components/Typography'

export default ({ jobs }) =>
  jobs.map((work, id) => (
    <section key={id}>
      <WorkHeading as="h2">
        <a href={work.url}>{work.company}</a>
      </WorkHeading>
      <Time>
        {work.start} —{work.end}
      </Time>

      <div
        dangerouslySetInnerHTML={{
          __html: `<p>${work.content.replace(/\n/g, '</p><p>')}</p>`,
        }}
      />
    </section>
  ))
