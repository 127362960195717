import React from 'react'
import { Aside } from '../components/Typography'
import { List, TextBlock, Skills } from './blocks'

export default ({ blocks }) => (
  <Aside>
    {blocks.map((block, id) => {
      switch (block.type) {
        case 'block':
          return (
            <TextBlock key={id} title={block.title} content={block.content} />
          )
        case 'skills':
          return <Skills key={id} title={block.title} content={block.content} />

        default:
        case 'list':
          return <List key={id} title={block.title} content={block.content} />
      }
    })}
  </Aside>
)
