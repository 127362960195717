import React from 'react'
import { WorkHeading, H3, Grid, GridFullWidth } from '../components/Typography'

export default ({ projects }) => (
  <Grid>
    <GridFullWidth>
      <WorkHeading as="h2">Side projects</WorkHeading>
    </GridFullWidth>
    {projects.map(({ title, url, content }, id) => (
      <section key={id}>
        <H3 as="h3">
          <a href={url}>{title}</a>
        </H3>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </section>
    ))}
  </Grid>
)
