import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import Work from '../components/work'
import Project from '../components/project'
import Aside from '../components/aside'
import {
  Main,
  Wrap,
  WrapInner,
  AsideWrap,
  Article,
} from '../components/Typography'
import { List } from '../components/blocks'

import Page from '../../content/index.yaml'

export default () => (
  <Layout>
    <Header title={Page.title} content={Page.header} />
    <Main>
      <Wrap>
        <WrapInner>
          <Article>
            <Work jobs={Page.jobs} />
            <Project projects={Page.projects} />
            <List content={Page.skills.content} title={Page.skills.title} />
          </Article>
        </WrapInner>
        <AsideWrap>
          <Aside blocks={Page.aside} />
        </AsideWrap>
      </Wrap>
    </Main>
  </Layout>
)
