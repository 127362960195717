import React from 'react'
import { Header, H1 } from '../components/Typography'

export default ({ title, content }) => (
  <Header>
    <div>
      <H1>{title}</H1>

      <div
        dangerouslySetInnerHTML={{
          __html: `<p>${content.replace(/\n/g, '</p><p>')}</p>`,
        }}
      />
    </div>
  </Header>
)
