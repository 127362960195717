import React from 'react'
import {
  H2,
  SkillsList,
  SkillsItem,
  SkillsItemSpan,
} from '../components/Typography'

export const Skills = ({ title, content }) => (
  <div>
    <H2 as="h2">{title}</H2>
    <SkillsList>
      {content.map((skill, id) => (
        <SkillsItem key={id}>
          <SkillsItemSpan style={{ width: `${skill.level}%` }}>
            {skill.title}
          </SkillsItemSpan>
        </SkillsItem>
      ))}
    </SkillsList>
  </div>
)

export const List = ({ title, content }) => (
  <div>
    <H2 as="h2">{title}</H2>
    <p>{content.join(', ')}</p>
  </div>
)

export const TextBlock = ({ title, content }) => (
  <div>
    <H2 as="h2">{title}</H2>
    <div dangerouslySetInnerHTML={{ __html: content }} />
  </div>
)
